<script>
/******************************************************************************
 * METER Component
 * this is the parent component for a single meter;
 * it can have multiple meterCounters (HT/NT etc)
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import MeterReadingDate from "./MeterReadingDate";
import MeterReading from "./MeterReading";
export default {
    props: {
        meterNumber: {type: String, required: true}
    },
    components: { Icon, MeterReading, MeterReadingDate },
    computed: {
        meterItems () {
            return this.$store.getters.getMeteringItemsByMeterNumber(this.meterNumber);
        },
        meterData () { return this.meterItems[0]; },
        iconName () {
            switch(this.meterData.meterMediaType) {
                case 1:
                    return "power";
                case 2:
                    return "gas";
                case 3:
                case 4:
                    return "water";
                case 5:
                    return "thermal";
                default:
                    return "";
            }
        },
        mediaType () {
            switch(this.meterData.meterMediaType) {
                case 1:
                    return "Strom";
                case 2:
                    return "Erdgas";
                case 3:
                case 4:
                    return "Wasser";
                case 5:
                    return "Fernwärme";
                default:
                    return "";
            }
        },
        dateType () { return this.$store.getters.dateType; },
        meteringPoint () {
            const point = this.meterData;
            if (!point) return;
            return `${point.meterLocationStreet} ${point.meterLocationStreetNumber}, ${point.meterLocationPoCode} ${point.meterLocationCity}`
        }
    }
}
</script>

<template>
    <article>
        <header class="clearfix">
            <div class="media-type clearfix">
                <icon
                    class="media-type-icon"
                    :name="iconName"
                    size="3" />
                <span class="text">{{ mediaType }}-Zähler</span>
            </div>
            <aside class="meter-number">
                <div class="box">
                    Zählernummer:
                    <span>{{ meterData.meterNumber }}</span>
                </div>
            </aside>
            <div class="meter-point">
                Abnahmestelle: {{ meteringPoint }}
            </div>
        </header>
        <meter-reading-date
            v-if="dateType === 'individual'"
            :meter-number="meterData.meterNumber" />
        <meter-reading
            v-if="meterItems.length"
            v-for="item in meterItems"
            :key="`${item.meterNumber}-${item.meterItemNumber}`"
            :meter-number="item.meterNumber"
            :meter-item-number="item.meterItemNumber" />
    </article>
</template>

<style lang="scss" scoped>
    header {
        padding: #{map-get($sizes, "base") * 0.6};
        margin: 0 0 #{map-get($sizes, "base")} 0;

        background: map-get($client, "meterHeaderBg");

        @include respond-to("small") {
            .media-type { float: left; }

            .meter-number { float: right; }
        }

        .media-type {
            .media-type-icon,
            .text {
                float: left;

                line-height: #{map-get($sizes, "base") * 2.4};
            }

            .media-type-icon {
                margin: #{map-get($sizes, "base") * 0.2} #{map-get($sizes, "base") / 1} 0 0;
            }

            .text {
                font-size: #{map-get($sizes, "base") * 1.2};
                font-weight: 400;
            }
        }

        .meter-number {
            margin-top: #{map-get($sizes, "base") / 2};

            @include respond-to("small") { margin-top: 0; }

            .box {
                display: inline-block;

                padding: #{map-get($sizes, "base") / 2.5};
                border: map-get($sizes, "border") solid map-get($client, "dividerBg");

                background-color: map-get($client, "appBg");

                > span {
                    font-weight: 500;
                }
            }
        }

        .meter-point {
            clear: both;

            margin-top: #{map-get($sizes, "base") * 0.3};
        }
    }
</style>
