<script>
/******************************************************************************
 * METERS
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import Meter from "./Meter";
export default {
    components: { PageSection, "ivu-meter": Meter },
    computed: {
        // we need to filter meters since there can be duplicates
        // HT/NT meters have same ZAE_NR / MES_ID, different KNZ_ZAEHLWERKNR
        meterNumbers () {
            const meterNumbers = this.$store.getters.meters
                .map(meter => meter.meterNumber);
            const readings = this.$store.getters.meterReadings;
            let filteredMeters = [];
            meterNumbers.forEach(meter => {
                const hasReadings = readings.filter(
                    reading => reading.meterNumber === meter
                        && reading.meterReadingValue !== undefined
                        && reading.meterReadingValue !== ""
                ).length > 0;
                console.log("has readings ", hasReadings);
                if (!filteredMeters.includes(meter) && hasReadings) {
                    filteredMeters.push(meter);
                }
            });
            return filteredMeters;
        }
    }
}
</script>

<template>
    <div>
        <ivu-meter
            v-for="meterNumber in meterNumbers"
            :key="meterNumber"
            :meter-number="meterNumber" />
    </div>
</template>
